import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import tempAlert from "../../components/alert/alert";
import DeleteConfirm from "../../components/alert/deleteConfirm";
import search from "../../img/icones/search.png";
import logoNormal from "../../img/logos/logo.png";
import "./manager.css";

export const BlogList = (props) => {
  const conexao = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
  });
  const { statusPesquisa, setStatusPesquisa } = props;
  const history = useHistory();
  const [blog, setBlog] = useState([
    {
      titulo: "",
      descricao: "",
      conteudo: "",
      photo: "",
    },
  ]);
  const [confirmState, setConfirmState] = useState(false);
  const [tempTitulo, setTempTitulo] = useState("");
  const [tempId, setTempId] = useState("");

  const doGetBlog = async (pagina, termo) => {
    const response = await conexao.get(`/blog/?titulo=${termo}`);
    setBlog(response.data);
  };

  useEffect(() => {
    doGetBlog(statusPesquisa.páginaAtual, statusPesquisa.termoDePesquisa);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doExcluirBlog = async (id, titulo) => {
    await conexao.delete(`/blog/${id}`);
    tempAlert("Blog de " + titulo + " excluído!", 5000);
    setConfirmState(false);
  };

  const handleExcluir = (id, titulo) => {
    setConfirmState(true);
    setTempId(id);
    setTempTitulo(titulo);
  };

  const handleSearchInputChange = async (event) => {
    const novoStatusPesquisa = {
      ...statusPesquisa,
      termoDePesquisa: event.target.value,
    };
    setStatusPesquisa(novoStatusPesquisa);
  };

  useEffect(() => {
    doGetBlog(statusPesquisa.páginaAtual, statusPesquisa.termoDePesquisa);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusPesquisa.termoDePesquisa]);

  const renderConfirmDelete = () => {
    return (
      <DeleteConfirm
        estado={confirmState}
        doExcluirBlog={doExcluirBlog}
        id={tempId}
        titulo={tempTitulo}
        setConfirmState={setConfirmState}
      ></DeleteConfirm>
    );
  };

  const tableData =
    blog.length === 0 ? (
      <p>Nada encontrado!</p>
    ) : (
      blog.map((row, index) => {
        return (
          <div className="tb" key={index}>
            <div className="tb-price">
              <button
                onClick={() => history.push(`/manager/editar/${row._id}`)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
              <button onClick={() => handleExcluir(row._id, row.titulo)}>
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
            <div className="tb-title">
              <p>{row.descricao}</p>
              <h2>{row.titulo}</h2>
              <span>{row.conteudo}</span>
            </div>
          </div>
        );
      })
    );

  return (
    <>
      {renderConfirmDelete()}
      <div className="">
        <nav className="header container flex_center_row">
          <a href="/" className="logo flex_center_row">
            <img src={logoNormal} className="img " alt="" />
            <div className="l_text flex_center_column ">
              <h1 className="ativo">IGUAÇU</h1>
              <p className="ativo">CONTÁBIL</p>
            </div>
          </a>
          <div className="menu_list">
            <ul className="flex_center_row">
              <a href="/">
                <button className="btn-click bl-exit">Sair</button>
              </a>
            </ul>
          </div>
        </nav>
      </div>
      <section className="bl-container">
        <form className="bl-form">
          <img src={search} className="bl-img" alt=""></img>
          <input
            className="bl-search"
            type="text"
            autoFocus
            value={statusPesquisa.termoDePesquisa}
            placeholder="Digite o título do blog que deseja procurar"
            onChange={handleSearchInputChange}
          />
          <div
            className="bl-add "
            onClick={() => history.push("/manager/novo")}
          >
            +
          </div>
        </form>
        <div className="bl-data">{tableData}</div>
      </section>
    </>
  );
};
