import { Footer } from "../../components/footer/Footer";
import { Hero } from "../../components/hero/Hero";
import { MenuList } from "../../components/menu/MenuList";
import { Topper } from "../../components/topper/Topper";
import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams } from "react-router-dom";
import "./LinksPage.css";
import $ from "jquery";
import {
  cofins,
  advertencia,
  admissional,
  rescisao,
  tabela1,
  tabela2,
  tabela4,
  tabela5,
  tabela3,
  mei,
  autonomous,
  cst,
  cnae,
  fps,
} from "../../docs/index";

export const LinksPage = () => {
  const { goTo } = useParams();

  useEffect(() => {
    $("body").toggleClass("open");
    window.scrollTo({
      top: goTo ? document.getElementById(goTo).offsetTop - 120 : 0,
      behavior: "smooth",
    });
  }, [goTo]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Iguaçu Contábil | Links</title>
        </Helmet>
      </HelmetProvider>
      <Topper></Topper>
      <MenuList></MenuList>
      <Hero page="Links"></Hero>
      <section className="links">
        <div className="container">
          <div className="tribu-links">
            <h1>Contábeis</h1>
            <ul className="link-wrapper">
              <li>
                <a
                  href="http://www.receita.fazenda.gov.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Receita Federal
                </a>
              </li>
              <li>
                <a
                  href="http://www.fazenda.pr.gov.br/virada.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sefaz/PR
                </a>
              </li>
              <li>
                <a
                  href="http://www.parana.pr.gov.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Governo do Estado de PR
                </a>
              </li>
              <li>
                <a
                  href="http://www.pgfn.fazenda.gov.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="PGFN"
                >
                  PGFN
                </a>
              </li>
              <li>
                <a
                  href="http://www.previdencia.gov.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Previdência Social
                </a>
              </li>
              <li>
                <a
                  href="http://www.planalto.gov.br/ccivil_03/decreto-lei/del5452.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CLT
                </a>
              </li>
              <li>
                <a
                  href="http://consulta.mte.gov.br/empregador/cbo/procuracbo/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CBO
                </a>
              </li>
              <li>
                <a
                  href="http://sijut.fazenda.gov.br/netahtml/sijut/SijutIntAsp/ATTIPI00.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tabela TIPI
                </a>
              </li>
              <li>
                <a
                  href="http://www.sintegra.gov.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sintegra
                </a>
              </li>
              <li>
                <a
                  href="http://www.suframa.gov.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Suframa
                </a>
              </li>
              <li>
                <a
                  href="http://www.mte.gov.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Ministério do Trabalho"
                >
                  Ministério do Trabalho
                </a>
              </li>
              <li>
                <a
                  href="https://www.sescap-pr.org.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SECAP/PR
                </a>
              </li>
              <li>
                <a
                  href="http://www.iobonlineregulatorio.com.br/pages/core/login.jsf"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="IOB"
                >
                  IOB
                </a>
              </li>
              <li>
                <a
                  href="http://www1.receita.fazenda.gov.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="PRED"
                >
                  PRED
                </a>
              </li>
              <li>
                <a
                  href="http://www.nfe.fazenda.gov.br/portal/principal.aspx"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="NFe"
                >
                  NFe
                </a>
              </li>
              <li>
                <a
                  href="http://portalcfc.org.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="CFC"
                >
                  CFC
                </a>
              </li>
              <li>
                <a
                  href="https://www3.crcpr.org.br/crcpr/portal/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CRC/PR
                </a>
              </li>
            </ul>
          </div>
          <div className="tribu-links">
            <h1>Bancários</h1>
            <ul className="link-wrapper">
              <li>
                <a
                  href="http://www.caixa.gov.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Caixa Econômica Federal
                </a>
              </li>
              <li>
                <a
                  href="http://www.bb.com.br/portalbb/home29,116,116,1,1,1,1.bb"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Banco Do Brasil
                </a>
              </li>
              <li>
                <a
                  href="http://www.bcb.gov.br/pt-br/paginas/default.aspx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Banco Central do Brasil
                </a>
              </li>
              <li>
                <a
                  href="http://www.bndes.gov.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  BNDES
                </a>
              </li>
              <li>
                <a
                  href="https://www.itau.com.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Itaú
                </a>
              </li>
              <li>
                <a
                  href="http://www.santander.com.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Banco Santander
                </a>
              </li>
              <li>
                <a
                  href="http://www.bradesco.com.br/html/classic/index.shtm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Bradesco
                </a>
              </li>
            </ul>
          </div>
          <div className="tribu-links">
            <h1>Jurídicos</h1>
            <ul className="link-wrapper">
              <li>
                <a
                  href="http://www.stf.jus.br/portal/principal/principal.asp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Supremo Tribunal Federal
                </a>
              </li>
              <li>
                <a
                  href="http://www.stj.jus.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Superior Tribunal da Justiça
                </a>
              </li>
              <li>
                <a
                  href="https://www.tjpr.jus.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tribunal de Justiça/PR
                </a>
              </li>
              <li>
                <a
                  href="http://www.cjf.jus.br/cjf"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Conselho da Justiça Federal"
                >
                  Conselho da Justiça Federal
                </a>
              </li>
              <li>
                <a
                  href="http://www.tst.jus.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Tribunal Superior do Trabalho"
                >
                  Tribunal Superior do Trabalho
                </a>
              </li>
              <li id="at">
                <a
                  href="https://www.trt9.jus.br/portal/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  TRT/PR
                </a>
              </li>
              <li>
                <a
                  href="http://www.tse.jus.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Tribunal Superior Eleitoral"
                >
                  Tribunal Superior Eleitoral
                </a>
              </li>
              <li>
                <a
                  href="https://www.tre-pr.jus.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  TRE/PR
                </a>
              </li>
              <li>
                <a
                  href="http://www.planalto.gov.br/ccivil_03/Constituicao/Constituicao.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Constituição"
                >
                  Constituição
                </a>
              </li>
              <li>
                <a
                  href="http://www4.planalto.gov.br/legislacao"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Portal da Legislação"
                >
                  Portal da Legislação
                </a>
              </li>
              <li>
                <a
                  href="http://idg.receita.fazenda.gov.br/acesso-rapido/legislacao/legislacao-por-assunto/legislacao-por-assuntos"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Legislação por Assunto"
                >
                  Legislação por Assunto
                </a>
              </li>
              <li>
                <a
                  href="http://www.planalto.gov.br/ccivil_03/LEIS/_Lei-Ordinaria.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Leis Ordinárias"
                >
                  Leis Ordinárias
                </a>
              </li>
              <li>
                <a
                  href="http://www.planalto.gov.br/ccivil_03/Leis/LCP/Quadro_Lcp.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Leis Complementares"
                >
                  Leis Complementares
                </a>
              </li>
              <li>
                <a
                  href="http://www4.planalto.gov.br/legislacao/legislacao-1/decretos1#content"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Decretos"
                >
                  Decretos
                </a>
              </li>
              <li>
                <a
                  href="http://www4.planalto.gov.br/legislacao/legislacao-1/decretos-leis#content"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Decretos-Leis"
                >
                  Decretos-Leis
                </a>
              </li>
              <li>
                <a
                  href="http://www.camara.gov.br/internet/sileg/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Projetos de Lei"
                >
                  Projetos de Lei
                </a>
              </li>
              <li>
                <a
                  href="http://www4.planalto.gov.br/legislacao/legislacao-1/medidas-provisorias#content"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Medidas Provisórias"
                >
                  Medidas Provisórias
                </a>
              </li>
              <li>
                <a
                  href="http://www4.planalto.gov.br/legislacao/legislacao-1/codigos-1#content"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Códigos"
                >
                  Códigos
                </a>
              </li>
              <li>
                <a
                  href="http://www.stf.jus.br/portal/jurisprudencia/pesquisarJurisprudencia.asp"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Jurisprudência"
                >
                  Jurisprudência
                </a>
              </li>
              <li>
                <a
                  href="http://www.stf.jus.br/portal/processo/pesquisarProcesso.asp"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Acompanhamento Processual"
                >
                  Acompanhamento Processual
                </a>
              </li>
              <li>
                <a
                  href="http://www.centraljuridica.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Central Jurídica"
                >
                  Central Jurídica
                </a>
              </li>
              <li>
                <a
                  href="http://www.oab.org.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="OAB Federal"
                >
                  OAB Federal
                </a>
              </li>
              <li>
                <a
                  href="https://www.oabpr.org.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  OAB/PR
                </a>
              </li>
            </ul>
          </div>
          <div className="tribu-links" id="tributario">
            <h1>Tributários</h1>
            <ul className="link-wrapper">
              <li>
                <a
                  href="https://www.gov.br/receitafederal/pt-br/assuntos/agenda-tributaria"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Agenda Tributária
                </a>
              </li>
              <li>
                <a
                  href="http://www.receita.fazenda.gov.br/Aplicacoes/ATRJO/Simulador/simulador.asp?tipoSimulador=M"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Alíquota Efetiva - IRPF
                </a>
              </li>
              <li>
                <a
                  href="http://www.cdw.fazenda.pr.gov.br/cdw/emissao/certidaoAutomatica"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Certidão Da Receita Estadual - Paraná
                </a>
              </li>
              <li>
                <a
                  href="https://consulta-crf.caixa.gov.br/consultacrf/pages/consultaEmpregador.jsf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CRF FGTS
                </a>
              </li>
              <li>
                <a
                  href="http://servicos.receita.fazenda.gov.br/Servicos/certidao/CndConjuntaInter/InformaNICertidao.asp?Tipo=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CND Federal – Receita Federal e INSS
                </a>
              </li>
              <li>
                <a
                  href="http://www.tst.jus.br/certidao"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CNDT{" "}
                </a>
              </li>
              <li>
                <a
                  href="http://servicos.receita.fazenda.gov.br/Servicos/certidao/CndConjuntaInter/InformaNICertidao.asp?Tipo=2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CND Federal - Pessoa Física
                </a>
              </li>
              <li>
                <a
                  href="https://receita.economia.gov.br/orientacao/tributaria/pagamentos-e-parcelamentos/darf-calculo-e-impressao-programa-sicalc-1/programa-para-calculo-e-emissao-de-darf-on-line-de-tributos-e-contribuicoes-federais-exceto-contribuicoes-previdenciarias"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DARF Comum
                </a>
              </li>
              <li>
                <a
                  href="http://www.receita.fazenda.gov.br/Aplicacoes/ATSPO/CertidaoITR/Certidao/Emissao"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ITR - Certidão Imóvel Rural
                </a>
              </li>
              <li>
                <a
                  href="https://www.gov.br/receitafederal/pt-br/assuntos/orientacao-tributaria/tributos/irpf-imposto-de-renda-pessoa-fisica"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  IRPF
                </a>
              </li>
              <li>
                <a
                  href="https://www.gov.br/inss/pt-br"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  INSS
                </a>
              </li>
              <li>
                <a
                  href="http://receita.economia.gov.br/orientacao/tributaria/pagamentos-e-parcelamentos/emissao-e-pagamento-de-darf-das-gps-e-dae/calculo-de-contribuicoes-previdenciarias-e-emissao-de-gps/tabela-de-incidencia-de-contribuicao"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Incidência INSS/FGTS/IRRF
                </a>
              </li>
              <li>
                <a
                  href="https://www.gov.br/inss/pt-br/saiba-mais/seus-direitos-e-deveres/calculo-da-guia-da-previdencia-social-gps/tabela-de-contribuicao-mensal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tabela de contribuição mensal - INSS
                </a>
              </li>
              <li>
                <a
                  href="https://servicos.receita.fazenda.gov.br/Servicos/ConsRest/Atual.app/paginas/index.asp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Restituições IRPF
                </a>
              </li>
            </ul>
          </div>
          <div className="tribu-links" id="tabelas">
            <h1>Tabelas Práticas</h1>
            <ul className="link-wrapper">
              <li>
                <a
                  href="https://www.gov.br/inss/pt-br/saiba-mais/seus-direitos-e-deveres/calculo-da-guia-da-previdencia-social-gps/tabela-de-contribuicao-mensal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tabela de contribuição mensal - INSS
                </a>
              </li>
              <li>
                <a href={fps} target="_blank" rel="noopener noreferrer">
                  Tabela FPS e Alíquota RAT
                </a>
              </li>
              <li>
                <a
                  href="https://www.sefaz.pe.gov.br/Legislacao/Tributaria/Documents/Legislacao/Tabelas/CFOP.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tabela Fiscal de Operações e Prestações (CFOP)
                </a>
              </li>
              <li>
                <a href={cst} target="_blank" rel="noopener noreferrer">
                  Tabela de Correlação CST x CSOSN-CRT
                </a>
              </li>
              <li>
                <a href={cofins} target="_blank" rel="noreferrer">
                  Tabela CST – PIS e COFINS
                </a>
              </li>
              <li>
                <a href={tabela1} target="_blank" rel="noopener noreferrer">
                  Tabela I Comércio - Simples Nacional
                </a>
              </li>
              <li>
                <a href={tabela2} target="_blank" rel="noopener noreferrer">
                  Tabela II Indústria - Simples Nacional
                </a>
              </li>
              <li>
                <a href={tabela3} target="_blank" rel="noopener noreferrer">
                  Tabela III Serviços - Simples Nacional
                </a>
              </li>
              <li>
                <a href={tabela4} target="_blank" rel="noopener noreferrer">
                  Tabela IV Serviços - Simples Nacional
                </a>
              </li>
              <li>
                <a href={tabela5} target="_blank" rel="noopener noreferrer">
                  Tabela V Serviços - Simples Nacional
                </a>
              </li>
              <li>
                <a href={cnae} target="_blank" rel="noopener noreferrer">
                  Tabela de Códigos previstos na CNAE
                </a>
              </li>
              <li>
                <a href={mei} target="_blank" rel="noopener noreferrer">
                  Tabela de Atividades Permitidas ao MEI
                </a>
              </li>
            </ul>
          </div>
          <div className="tribu-links" id="formularios">
            <h1>Formulários</h1>
            <ul className="link-wrapper">
              <li>
                <a href={admissional} target="_blank" rel="noopener noreferrer">
                  Cadastro de Admissão
                </a>
              </li>
              <li>
                <a href={advertencia} target="_blank" rel="noopener noreferrer">
                  Carta de Advertência
                </a>
              </li>
              <li>
                <a href={rescisao} target="_blank" rel="noopener noreferrer">
                  Cálculo de Rescisão
                </a>
              </li>
              <li>
                <a href={autonomous} target="_blank" rel="noopener noreferrer">
                  RPA (Recibo de Pagamento a Autônomo)
                </a>
              </li>
            </ul>
          </div>
          <div className="tribu-links">
            <h1>Diversos</h1>
            <ul className="link-wrapper">
              <li>
                <a
                  href="https://www3.bcb.gov.br/CALCIDADAO/publico/exibirFormCorrecaoValores.do?method=exibirFormCorrecaoValores"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Calculadora do cidadão
                </a>
              </li>
              <li>
                <a
                  href="https://www.cartorio24horas.com.br/index.php"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cartório 24 Horas
                </a>
              </li>
              <li>
                <a
                  href="https://www.receita.fazenda.gov.br/Aplicacoes/SSL/ATCTA/CPF/ConsultaSituacao/ConsultaPublica.asp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Consulta CPF
                </a>
              </li>
              <li>
                <a
                  href="http://www.receita.fazenda.gov.br/pessoajuridica/cnpj/cnpjreva/cnpjreva_solicitacao.asp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Consulta CNPJ
                </a>
              </li>
              <li>
                <a
                  href="https://concla.ibge.gov.br/busca-online-cnae.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Código Nacional de Atividades Econômicas
                </a>
              </li>
              <li>
                <a
                  href="http://www.caixa.gov.br/beneficios-trabalhador/pis/Paginas/default.aspx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Consulta PIS
                </a>
              </li>
              <li>
                <a
                  href="http://www.cnae.ibge.gov.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Consulta CNAE
                </a>
              </li>
              <li>
                <a
                  href="https://portal.esocial.gov.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  E-Social
                </a>
              </li>
              <li>
                <a
                  href="http://trabalho.gov.br/portal-mte/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ministério do Trabalho
                </a>
              </li>
              <li>
                <a
                  href="http://www8.receita.fazenda.gov.br/SimplesNacional/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Simples Nacional
                </a>
              </li>
              <li>
                <a
                  href="http://www.portaldoempreendedor.gov.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Portal do MEI
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
};
