import React from "react";
import "./Hero.css";

export const Hero = (props) => {
  return (
    <>
      <section className="home-about" id="home">
        <div className="filler-about"></div>
        <div className="home-banner">
          <div className="container flex_center_column">
            {props.noticia ? (
              <>
                <h2 id="nt-h1">{props.page}</h2>
              </>
            ) : (
              <>
                <h1>{props.page}</h1>
              </>
            )}
            <div className="text flex_center_row">
              {props.noticia ? (
                <></>
              ) : (
                <>
                  <a href="/"> Home</a>
                  <p id="blog-head">/ {props.page}</p>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
