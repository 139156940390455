/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import $ from "jquery";
import "./HistoriaSlider.css";
import AOS from "aos";
import "aos/dist/aos.css";

export const HistoriaSlider = () => {
  useEffect(() => {
    AOS.init({
      easing: "ease",
      once: true,
    });

    $(document).ready(function ($) {
      var timelines = $(".cd-horizontal-timeline"),
        eventsMinDistance = 60;

      timelines.length > 0 && initTimeline(timelines);

      function initTimeline(timelines) {
        timelines.each(function () {
          var timeline = $(this),
            timelineComponents = {};
          //cache timeline components
          timelineComponents["timelineWrapper"] = timeline.find(".events-wrapper");
          timelineComponents["eventsWrapper"] = timelineComponents["timelineWrapper"].children(".events");
          timelineComponents["fillingLine"] = timelineComponents["eventsWrapper"].children(".filling-line");
          timelineComponents["timelineEvents"] = timelineComponents["eventsWrapper"].find("a");
          timelineComponents["timelineDates"] = parseDate(timelineComponents["timelineEvents"]);
          timelineComponents["eventsMinLapse"] = minLapse(timelineComponents["timelineDates"]);
          timelineComponents["timelineNavigation"] = timeline.find(".cd-timeline-navigation");
          timelineComponents["eventsContent"] = timeline.children(".events-content");

          //assign a left postion to the single events along the timeline
          setDatePosition(timelineComponents, eventsMinDistance);
          //assign a width to the timeline
          var timelineTotWidth = setTimelineWidth(timelineComponents, eventsMinDistance);
          //the timeline has been initialize - show it
          timeline.addClass("loaded");

          //detect click on the next arrow
          timelineComponents["timelineNavigation"].on("click", ".next", function (event) {
            event.preventDefault();
            updateSlide(timelineComponents, timelineTotWidth, "next");
          });
          //detect click on the prev arrow
          timelineComponents["timelineNavigation"].on("click", ".prev", function (event) {
            event.preventDefault();
            updateSlide(timelineComponents, timelineTotWidth, "prev");
          });
          //detect click on the a single event - show new event content
          timelineComponents["eventsWrapper"].on("click", "a", function (event) {
            event.preventDefault();
            timelineComponents["timelineEvents"].removeClass("selected");
            $(this).addClass("selected");
            updateOlderEvents($(this));
            updateFilling($(this), timelineComponents["fillingLine"], timelineTotWidth);
            updateVisibleContent($(this), timelineComponents["eventsContent"]);
          });

          //on swipe, show next/prev event content
          timelineComponents["eventsContent"].on("swipeleft", function () {
            var mq = checkMQ();
            mq === "mobile" && showNewContent(timelineComponents, timelineTotWidth, "next");
          });
          timelineComponents["eventsContent"].on("swiperight", function () {
            var mq = checkMQ();
            mq === "mobile" && showNewContent(timelineComponents, timelineTotWidth, "prev");
          });

          //keyboard navigation
          $(document).keyup(function (event) {
            if (event.which === "37" && elementInViewport(timeline.get(0))) {
              showNewContent(timelineComponents, timelineTotWidth, "prev");
            } else if (event.which === "39" && elementInViewport(timeline.get(0))) {
              showNewContent(timelineComponents, timelineTotWidth, "next");
            }
          });
        });
      }

      function updateSlide(timelineComponents, timelineTotWidth, string) {
        //retrieve translateX value of timelineComponents['eventsWrapper']
        var translateValue = getTranslateValue(timelineComponents["eventsWrapper"]),
          wrapperWidth = Number(timelineComponents["timelineWrapper"].css("width").replace("px", ""));
        //translate the timeline to the left('next')/right('prev')
        string === "next"
          ? translateTimeline(
              timelineComponents,
              translateValue - wrapperWidth + eventsMinDistance,
              wrapperWidth - timelineTotWidth
            )
          : translateTimeline(timelineComponents, translateValue + wrapperWidth - eventsMinDistance);
      }

      function showNewContent(timelineComponents, timelineTotWidth, string) {
        //go from one event to the next/previous one
        var visibleContent = timelineComponents["eventsContent"].find(".selected"),
          newContent = string === "next" ? visibleContent.next() : visibleContent.prev();

        if (newContent.length > 0) {
          //if there's a next/prev event - show it
          var selectedDate = timelineComponents["eventsWrapper"].find(".selected"),
            newEvent =
              string === "next"
                ? selectedDate.parent("li").next("li").children("a")
                : selectedDate.parent("li").prev("li").children("a");

          updateFilling(newEvent, timelineComponents["fillingLine"], timelineTotWidth);
          updateVisibleContent(newEvent, timelineComponents["eventsContent"]);
          newEvent.addClass("selected");
          selectedDate.removeClass("selected");
          updateOlderEvents(newEvent);
          updateTimelinePosition(string, newEvent, timelineComponents, timelineTotWidth);
        }
      }

      function updateTimelinePosition(string, event, timelineComponents, timelineTotWidth) {
        //translate timeline to the left/right according to the position of the selected event
        var eventStyle = window.getComputedStyle(event.get(0), null),
          eventLeft = Number(eventStyle.getPropertyValue("left").replace("px", "")),
          timelineWidth = Number(timelineComponents["timelineWrapper"].css("width").replace("px", "")),
          timelineTotWidth = Number(timelineComponents["eventsWrapper"].css("width").replace("px", ""));
        var timelineTranslate = getTranslateValue(timelineComponents["eventsWrapper"]);

        if (
          (string === "next" && eventLeft > timelineWidth - timelineTranslate) ||
          (string === "prev" && eventLeft < -timelineTranslate)
        ) {
          translateTimeline(timelineComponents, -eventLeft + timelineWidth / 2, timelineWidth - timelineTotWidth);
        }
      }

      function translateTimeline(timelineComponents, value, totWidth) {
        var eventsWrapper = timelineComponents["eventsWrapper"].get(0);
        value = value > 0 ? 0 : value; //only negative translate value
        value = !(typeof totWidth === "undefined") && value < totWidth ? totWidth : value; //do not translate more than timeline width
        setTransformValue(eventsWrapper, "translateX", value + "px");
        //update navigation arrows visibility
        value === 0
          ? timelineComponents["timelineNavigation"].find(".prev").addClass("inactive")
          : timelineComponents["timelineNavigation"].find(".prev").removeClass("inactive");
        value === totWidth
          ? timelineComponents["timelineNavigation"].find(".next").addClass("inactive")
          : timelineComponents["timelineNavigation"].find(".next").removeClass("inactive");
      }

      function updateFilling(selectedEvent, filling, totWidth) {
        //change .filling-line length according to the selected event
        var eventStyle = window.getComputedStyle(selectedEvent.get(0), null),
          eventLeft = eventStyle.getPropertyValue("left"),
          eventWidth = eventStyle.getPropertyValue("width");
        eventLeft = Number(eventLeft.replace("px", "")) + Number(eventWidth.replace("px", "")) / 2;
        var scaleValue = eventLeft / totWidth;
        setTransformValue(filling.get(0), "scaleX", scaleValue);
      }

      function setDatePosition(timelineComponents, min) {
        for (var i = 0; i < timelineComponents["timelineDates"].length; i++) {
          var distance = daydiff(timelineComponents["timelineDates"][0], timelineComponents["timelineDates"][i]),
            distanceNorm = Math.round(distance / timelineComponents["eventsMinLapse"]) + 5;
          timelineComponents["timelineEvents"].eq(i).css("left", distanceNorm * min + "px");
        }
      }

      function setTimelineWidth(timelineComponents, width) {
        var timeSpan = daydiff(
            timelineComponents["timelineDates"][0],
            timelineComponents["timelineDates"][timelineComponents["timelineDates"].length - 1]
          ),
          timeSpanNorm = timeSpan / timelineComponents["eventsMinLapse"],
          timeSpanNorm = Math.round(timeSpanNorm) + 12,
          totalWidth = timeSpanNorm * width;
        timelineComponents["eventsWrapper"].css("width", totalWidth + "px");
        updateFilling(timelineComponents["timelineEvents"].eq(0), timelineComponents["fillingLine"], totalWidth);

        return totalWidth;
      }

      function updateVisibleContent(event, eventsContent) {
        var classEnetering, classLeaving;
        var eventDate = event.data("date"),
          visibleContent = eventsContent.find(".selected"),
          selectedContent = eventsContent.find('[data-date="' + eventDate + '"]'),
          selectedContentHeight = selectedContent.height();

        if (selectedContent.index() > visibleContent.index()) {
          classEnetering = "selected enter-right";
          classLeaving = "leave-left";
        } else {
          classEnetering = "selected enter-left";
          classLeaving = "leave-right";
        }

        selectedContent.attr("class", classEnetering);
        visibleContent
          .attr("class", classLeaving)
          .one("webkitAnimationEnd oanimationend msAnimationEnd animationend", function () {
            visibleContent.removeClass("leave-right leave-left");
            selectedContent.removeClass("enter-left enter-right");
          });
        eventsContent.css("height", selectedContentHeight + "px");
      }

      function updateOlderEvents(event) {
        event
          .parent("li")
          .prevAll("li")
          .children("a")
          .addClass("older-event")
          .end()
          .end()
          .nextAll("li")
          .children("a")
          .removeClass("older-event");
      }

      function getTranslateValue(timeline) {
        var timelineStyle = window.getComputedStyle(timeline.get(0), null),
          timelineTranslate =
            timelineStyle.getPropertyValue("-webkit-transform") ||
            timelineStyle.getPropertyValue("-moz-transform") ||
            timelineStyle.getPropertyValue("-ms-transform") ||
            timelineStyle.getPropertyValue("-o-transform") ||
            timelineStyle.getPropertyValue("transform");

        if (timelineTranslate.indexOf("(") >= 0) {
          timelineTranslate = timelineTranslate.split("(")[1];
          timelineTranslate = timelineTranslate.split(")")[0];
          timelineTranslate = timelineTranslate.split(",");
          var translateValue = timelineTranslate[4];
        } else {
          translateValue = 0;
        }

        return Number(translateValue);
      }

      function setTransformValue(element, property, value) {
        element.style["-webkit-transform"] = property + "(" + value + ")";
        element.style["-moz-transform"] = property + "(" + value + ")";
        element.style["-ms-transform"] = property + "(" + value + ")";
        element.style["-o-transform"] = property + "(" + value + ")";
        element.style["transform"] = property + "(" + value + ")";
      }

      function parseDate(events) {
        var dateArrays = [];
        events.each(function () {
          var dateComp = $(this).data("date").split("/"),
            newDate = new Date(dateComp[2], dateComp[1] - 1, dateComp[0]);
          dateArrays.push(newDate);
        });
        return dateArrays;
      }

      function daydiff(first, second) {
        return Math.round(second - first);
      }

      function minLapse(dates) {
        var dateDistances = [];
        for (var i = 1; i < dates.length; i++) {
          var distance = daydiff(dates[i - 1], dates[i]);
          dateDistances.push(distance);
        }
        return Math.min.apply(null, dateDistances);
      }

      function elementInViewport(el) {
        var top = el.offsetTop;
        var left = el.offsetLeft;
        var width = el.offsetWidth;
        var height = el.offsetHeight;

        while (el.offsetParent) {
          el = el.offsetParent;
          top += el.offsetTop;
          left += el.offsetLeft;
        }

        return (
          top < window.pageYOffset + window.innerHeight &&
          left < window.pageXOffset + window.innerWidth &&
          top + height > window.pageYOffset &&
          left + width > window.pageXOffset
        );
      }

      function checkMQ() {
        return window
          .getComputedStyle(document.querySelector(".cd-horizontal-timeline"), "::before")
          .getPropertyValue("content")
          .replace(/'/g, "")
          .replace(/"/g, "");
      }
    });
  }, []);
  return (
    <>
      <div className="story flex_center_column" data-aos="zoom-out" data-aos-duration="1000">
        <h1>Nossa História</h1>
        <nav className="cd-horizontal-timeline">
          <div className="timeline">
            <div className="events-wrapper">
              <div className="events">
                <ol>
                  <li>
                    <a data-date="01/07/1992" className="selected">
                      Jul 1992
                    </a>
                  </li>
                  <li>
                    <a data-date="01/05/1994">Maio 1994</a>
                  </li>
                  <li>
                    <a data-date="01/03/2003">Mar 2003</a>
                  </li>
                </ol>

                <span className="filling-line" aria-hidden="true"></span>
              </div>
            </div>

            <ul className="cd-timeline-navigation">
              <li>
                <a className="prev inactive">Prev</a>
              </li>
              <li>
                <a className="next">Next</a>
              </li>
            </ul>
          </div>
          <div className="events-content">
            <ol>
              <li className="selected" data-date="01/07/1992">
                <h2>O início</h2>
                <em>Julho, 1992</em>
                <p>
                  Diante da realidade de um desemprego nosso sócio fundador, Jaime, se deparou com a necessidade de
                  produzir renda para sustento próprio e da família. Na época com apenas 24 anos e cursando o 3° ano de
                  contabilidade, foi estimulado pelo seu sogro e primeiro sócio, Sr. Cícero (in memoriam) – falecido em
                  06/09/2017, a iniciar a empresa que levava o nome de Escritório Contábil Iguaçu Ltda, o qual
                  homenageava as cataratas do Iguaçu. Nesta época a empresa funcionava num espaço de 35 m², ocupando uma
                  sala de um apartamento que fica na sobreloja do atual endereço da empresa. Mas desde o início já
                  possuíamos um computador de última geração que era um 386. A empresa já nasceu usufruindo das melhores
                  ferramentas de trabalho. A informática estava engatinhando, mas a ideia dos sócios fundadores sempre
                  foi de usar a tecnologia a favor das atividades da empresa.
                </p>
              </li>

              <li data-date="01/05/1994">
                <h2>Crescimento e estruturação</h2>
                <em>Maio, 1994</em>
                <p>
                  Em maio de 1994, já possuíamos uma carteira com 25 empresas, e já havia 06 meses que estávamos no
                  térreo do endereço da sede, num espaço maior com cerca de 75 m². Nesta ocasião tivemos a oportunidade
                  de ampliar a carteira de clientes, e adquirimos uma outra com 35 empresas chegando a um total de 60
                  clientes. Foi um período de muito trabalho para adequação e estruturação da empresa para atendimento
                  aos clientes. Nesta época por conta da aquisição da nova carteira, entendemos que seria necessário
                  estarmos no local onde os clientes eram acostumados a se dirigir e mudamos o endereço para o centro da
                  cidade num edifício comercial, onde funcionava o antigo escritório detentor da carteira de clientes
                  adquirida. Neste período também, através de parceria com um cliente que atuava na área de marketing e
                  publicidade foi desenvolvida a logomarca da empresa no formato de uma árvore, cujos fundamentos são: a
                  copa da árvore é verde e formada pelo mapa mundi, demonstrando que por conta da rede mundial de
                  computadores a empresa pode atender clientes em qualquer lugar, tendo também a preocupação com a
                  sustentabilidade, seu tronco na cor ouro, representa a riqueza produzida pelo trabalho e o valor que
                  pode agregar aos clientes com sua atividade.
                </p>
              </li>

              <li data-date="01/03/2003">
                <h2>Novo endereço</h2>
                <em>Março de 2003</em>
                <p>
                  Em 2003, os sócios decidiram que deveriam retornar ao endereço inicial, cujo prédio era de propriedade
                  do Sr. Cícero (in memorian), nesta ocasião o Sr. Cícero, à época com 63 anos aproximadamente, recém
                  formado em direito, propôs ao sócio Jaime que reformassem a parte térrea do edifício localizado na Av.
                  Morangueira, 426 (atual sede da empresa), visando adequá-lo para instalação da empresa novamente. Em
                  marco do mesmo ano, ocorreu a mudança para o novo endereço, onde a empresa está sediada até hoje.
                  Nesta mesma época o sócio Jaime já era estudante de direito cuja formatura ocorreu em 2005 e atuando
                  até hoje como advogado. Recentemente a empresa reformou a fachada da sua sede dando um visual mais
                  clean, mantendo porém a estrutura da logomarca desenvolvida em 2001. ocorreu a mudança para o novo
                  endereço, onde a empresa está sediada até hoje. Nesta mesma época o sócio Jaime já era estudante de
                  direito cuja formatura ocorreu em 2005 e atuando até hoje como advogado. Recentemente a empresa
                  reformou a fachada da sua sede dando um visual mais clean, mantendo porém a estrutura da logomarca
                  desenvolvida em 2001.
                </p>
              </li>
            </ol>
          </div>
        </nav>
      </div>
    </>
  );
};
