import { Footer } from "../../components/footer/Footer";
import { Hero } from "../../components/hero/Hero";
import { MenuList } from "../../components/menu/MenuList";
import { Topper } from "../../components/topper/Topper";
import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import $ from "jquery";
import "./ContatoPage.css";
import axios from "axios";
import { tempAlert } from "../../libraries/tempAlert";

const contactInitialState = {
  name: "",
  email: "",
  message: "",
  cellphone: "",
  send: false,
};

export const ContatoPage = () => {
  const [contact, setContact] = useState(contactInitialState);
  const conexao = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
  });

  const handleNewsletter = async (event) => {
    event.preventDefault();
    const newEmailContent = {
      to: "atendimento@iguacucontabil.com.br",
      subject: `Iguaçu Site Contato - ${contact.name}}`,
      text: `${contact.name}
      ${contact.cellphone}
      ${contact.message}`,
    };
    await conexao
      .post(`/email`, newEmailContent)
      .then(() => {
        setContact({ ...contact, send: true });
        tempAlert("Email enviado com sucesso!");
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    $("body").toggleClass("open");
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Iguaçu Contábil | Contato</title>
        </Helmet>
      </HelmetProvider>
      <Topper></Topper>
      <MenuList></MenuList>
      <Hero page="Contato"></Hero>
      <section className="hole-map">
        <div className="contato container flex_center_row">
          <a
            className="map-view"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.google.com/search?bih=937&biw=1920&rlz=1C1GCEA_enBR915BR915&hl=pt-BR&tbm=lcl&sxsrf=ALeKk03Wp-sexg3Z_Y5K-bBTpefzEIqc8Q:1613445325192&ei=zTgrYNGwC4265OUP9uGVoAs&q=iguaçu+contabil+maringa&oq=iguaçu+contabil+maringa&gs_l=psy-ab.3...3992.5501.0.5656.8.6.0.0.0.0.272.272.2-1.1.0....0...1c.1.64.psy-ab..7.1.272...0i22i30k1.0.8kcyJJuSrRo#rlfi=hd:;si:2211826999536500671;mv:[[-23.408748722680972,-51.93057588063004],[-23.409108677319026,-51.930968119369965]]"
          >
            veja no mapa
          </a>
          <div className="form">
            <h1 className="padrao-title">Entre em contato</h1>
            <form
              className="flex_center_column"
              id="form"
              onSubmit={handleNewsletter}
            >
              <div className="form-box" id="coma">
                <input
                  className="form-style"
                  type="text"
                  required
                  id="nome"
                  autoComplete="name"
                  placeholder="Nome"
                  value={contact.name}
                  onChange={(event) =>
                    setContact({ ...contact, name: event.target.value })
                  }
                />
                <i className="fas fa-check-circle"></i>
                <i className="fas fa-exclamation-circle"></i>
                <small>Mensagem de erro</small>
              </div>
              <div className="form-box">
                <input
                  className="form-style"
                  type="email"
                  required
                  id="email"
                  autoComplete="email"
                  placeholder="Email"
                  value={contact.email}
                  onChange={(event) =>
                    setContact({ ...contact, email: event.target.value })
                  }
                />
                <i className="fas fa-check-circle"></i>
                <i className="fas fa-exclamation-circle"></i>
                <small>Mensagem de erro</small>
              </div>
              <div className="form-box">
                <input
                  className="form-style"
                  type="tel"
                  required
                  id="celular"
                  autoComplete="tel"
                  placeholder="Celular"
                  value={contact.cellphone}
                  onChange={(event) =>
                    setContact({ ...contact, cellphone: event.target.value })
                  }
                />
                <i className="fas fa-check-circle"></i>
                <i className="fas fa-exclamation-circle"></i>
                <small>Mensagem de erro</small>
              </div>
              <div className="form-box" id="last-box">
                <textarea
                  placeholder="Mensagem"
                  type="text"
                  id="mensagem"
                  cols="30"
                  required
                  rows="10"
                  className="form-style"
                  value={contact.message}
                  onChange={(event) =>
                    setContact({ ...contact, message: event.target.value })
                  }
                ></textarea>
                <i className="fas fa-check-circle"></i>
                <i className="fas fa-exclamation-circle"></i>
                <small>Mensagem de erro</small>
              </div>
              <div className="button-box">
                <button type="submit" id="button" className="button">
                  {contact.send ? (
                    <svg
                      className="svg-file"
                      x="0px"
                      y="0px"
                      viewBox="0 0 25 30"
                    >
                      <path
                        className="check st0"
                        d="M2,19.2C5.9,23.6,9.4,28,9.4,28L23,2"
                      />
                    </svg>
                  ) : (
                    <p className="text" style={{ fontSize: "18px" }}>
                      Enviar
                    </p>
                  )}
                </button>
                {/* <div className="progress-bar"></div> */}
              </div>
            </form>
          </div>
        </div>
        <div className="void-filler"></div>
      </section>
      <Footer />
    </>
  );
};
