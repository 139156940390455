import $ from "jquery";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import logoNormal from "../../img/logos/logo.png";
import logoWhite from "../../img/logos/white.png";
import "./MenuList.css";

export const MenuList = (props) => {
  const history = useHistory();

  function keydownHandler(e) {
    if (e.keyCode === 113) {
      history.push("/login");
    }
  }
  useEffect(() => {
    document.addEventListener("keydown", keydownHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    $(window).detach();
    $(".menu-icon-toggle").on("click", function (e) {
      $("body").toggleClass("open");
      e.preventDefault();
    });

    // Inicia a pagina com scrool on the top
    document.getElementById("goToTop").scrollIntoView();

    $(window).on("scroll", function () {
      if ($(window).scrollTop() > 50) {
        $(".floating_nav_bar").addClass("ativo");
        $(".header").addClass("ativo");
        $(".floating_nav_bar_on").addClass("ativo");
        $(".img1").removeClass("ativo");
        $(".img2").addClass("ativo");
        $(".logo-text h1").addClass("ativo");
        $(".logo-text p").addClass("ativo");
      } else {
        $(".floating_nav_bar").removeClass("ativo");
        $(".header").removeClass("ativo");
        $(".floating_nav_bar_on").removeClass("ativo");
        $(".img2").removeClass("ativo");
        $(".img1").addClass("ativo");
        $(".logo-text h1").removeClass("ativo");
        $(".logo-text p").removeClass("ativo");
      }
      if ($(window).scrollTop() > 400) {
        $(".btn_top").addClass("ativo");
      } else {
        $(".btn_top").removeClass("ativo");
      }
    });
  }, []);

  return (
    <>
      <div id="goToTop"></div>
      <div className="floating_nav_bar">
        <nav className="header container flex_center_row">
          <a href="/" className="logo flex_center_row">
            <img src={logoWhite} className="img1 ativo" alt="" />
            <img src={logoNormal} className="img2" alt="" />
            <div className="logo-text flex_center_column">
              <h1>IGUAÇU</h1>
              <p>CONTÁBIL</p>
            </div>
          </a>
          <div className="menu_list">
            <ul className="flex_center_row">
              <a href="/">
                <li id="ativo">Home</li>
              </a>
              <button onClick={() => history.push("/about")}>
                <li>Sobre nós</li>
              </button>
              <button onClick={() => history.push(`/servicos`)}>
                <li>Serviços</li>
              </button>
              {/* <button onClick={() => history.push(`/blog`)}>
                <li>Notícias</li>
              </button> */}
              <button onClick={() => history.push(`/links`)}>
                <li>Links úteis</li>
              </button>
              <button onClick={() => history.push(`/contato`)}>
                <li>Contato</li>
              </button>
              <a href="https://app.gclick.com.br/" target="_blank" rel="noopener noreferrer">
                <button className="btn-click">G-Click</button>
              </a>
            </ul>
          </div>
          <nav className="menu-navigation">
            <button className="menu-icon-toggle">
              <span></span>
            </button>
            <i className="menu-background top"></i>
            <i className="menu-background middle"></i>
            <i className="menu-background bottom"></i>
            <ul className="menu">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <button onClick={() => history.push(`/about`)}>Sobre nós</button>
              </li>
              <li>
                <button onClick={() => history.push(`/servicos`)}>Serviços</button>
              </li>
              {/* <li>
                <button onClick={() => history.push(`/blog`)}>Notícias</button>
              </li> */}
              <li>
                <button onClick={() => history.push(`/links`)}>Links úteis</button>
              </li>
              <li>
                <button onClick={() => history.push(`/contato`)}>Contato</button>
              </li>
              <li>
                <a href="https://app.gclick.com.br/">G-click</a>
              </li>
            </ul>
          </nav>
        </nav>
      </div>
    </>
  );
};
