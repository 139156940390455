import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { HomePage } from "./pages/home/HomePage";
import { AboutPage } from "./pages/about/AboutPage";
import { BlogPage } from "./pages/blog/BlogPage";
import { ServicosPage } from "./pages/servicos/ServicosPage";
import { LinksPage } from "./pages/links/LinksPage";
import { ContatoPage } from "./pages/contato/ContatoPage";
import { LoginPage } from "./pages/login/LoginPage";
import { BlogManter } from "./pages/manager/BlogManter";
import { NoticiaPage } from "./pages/noticia/NoticiaPage";

import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <Switch>
            <Route exact path="/">
              <HomePage></HomePage>
            </Route>
            <Route exact path="/about">
              <AboutPage></AboutPage>
            </Route>
            <Route exact path="/servicos">
              <ServicosPage></ServicosPage>
            </Route>
            <Route exact path="/blog">
              <BlogPage></BlogPage>
            </Route>
            <Route exact path="/blog/noticia/:idDaNoticia">
              <NoticiaPage></NoticiaPage>
            </Route>
            <Route exact path="/links/:goTo?">
              <LinksPage></LinksPage>
            </Route>
            <Route exact path="/contato">
              <ContatoPage></ContatoPage>
            </Route>
            <Route exact path="/login">
              <LoginPage></LoginPage>
            </Route>
            <Route path="/manager">
              <BlogManter></BlogManter>
            </Route>
          </Switch>
        </Router>
      </header>
    </div>
  );
}

export default App;
