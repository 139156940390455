/* eslint-disable no-unused-vars */
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import { Footer } from "../../components/footer/Footer";
import { LoadingScreen } from "../../components/loading/LoadingScreen";
import { MenuList } from "../../components/menu/MenuList";
import { HomeSlider } from "../../components/slider/HomeSlider";
import { Topper } from "../../components/topper/Topper";
import money from "../../img/resumo/money.png";
import paper from "../../img/resumo/paper.png";
import table from "../../img/resumo/table.png";
import administrative from "../../img/servicos/administrative.png";
import contabilidade from "../../img/servicos/contabilidade.png";
import empresa from "../../img/servicos/empresa.png";
import financeiro from "../../img/servicos/financeiro.png";
import pessoal from "../../img/servicos/pessoal.png";
import societario from "../../img/servicos/societario.png";

export const HomePage = () => {
  const conexao = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
  });
  const history = useHistory();
  const [blog, setBlog] = useState([
    {
      titulo: "",
      descricao: "",
      conteudo: "",
    },
  ]);

  const doGetBlog = async () => {
    const response = await conexao.get(`/blog`);
    const blogTemp = [];
    for (let i = 0; i < 3; i++) {
      blogTemp[i] = response.data[i];
    }
    setBlog(blogTemp);
  };

  const tableData =
    blog.length === 0 ? (
      <p>Sem Blog Adicionado!</p>
    ) : (
      blog.map((row, index) => {
        return (
          <button
            onClick={() => history.push(`/blog/noticia/${row._id}`)}
            className="HomeBlog-box"
            key={index}
          >
            <h2>{row.descricao}</h2>
            <h1>{row.titulo}</h1>
            <div className="bp-conteudo">
              <p>{row.conteudo}</p>
            </div>
            <div className="HomeBlog-arrow">Saiba Mais</div>
          </button>
        );
      })
    );

  useEffect(() => {
    doGetBlog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    AOS.init({
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Iguaçu Contábil | Home</title>
        </Helmet>
      </HelmetProvider>
      <LoadingScreen></LoadingScreen>
      <Topper></Topper>
      <MenuList logo={false}></MenuList>
      <HomeSlider></HomeSlider>
      <section className="resumo">
        <button
          onClick={() => history.push("/links/tributario")}
          className="tab t1"
        >
          <img src={money} alt="" />
          <h1>Agenda Tributária</h1>
          <p>
            Fique informado com a agenda contábil estadual, federal e
            trabalhista.
          </p>
        </button>
        <button
          onClick={() => history.push("/links/tabelas")}
          className="tab t2"
        >
          <img src={table} alt="" />
          <h1>Tabelas Práticas</h1>
          <p>
            Tabelas para recolhimento em atraso, Simples Nacional, Imposto de
            Renda e Alíquotas.
          </p>
        </button>
        <button
          onClick={() => history.push("/links/formularios")}
          className="tab t3"
        >
          <img src={paper} alt="" />
          <h1>Formulários Diversos</h1>
          <p>
            Cadastro de Admissão, Carta de Advertência, Cálculo de Rescisão e
            RPA (Recibo de Pagamento a Autônomo)
          </p>
        </button>
        <div className="tab t4">
          <p>QUER FAZER UM ORÇAMENTO?</p>
          <h1>NÓS ENTRAMOS EM CONTATO COM VOCÊ</h1>
          <a href="https://api.whatsapp.com/send?l=pt_BR&phone=5504430261047">
            <button className="btn">Solicitar Orçamento</button>
          </a>
        </div>
      </section>
      <section className="servicos flex_center_column">
        <div className="container" data-aos="fade-up" data-aos-duration="1000">
          <div className="servicos-title flex_center_column">
            <h1>Nossos Serviços</h1>
            <p>
              Entender a necessidade da sua empresa, encontrar soluções
              inovadoras e compatíveis com o mercado,
              <br />
              associados à melhor solução técnica, faz parte da nossa essência.
            </p>
          </div>
          <div className="servicos-banners">
            <button
              onClick={() => history.push("/servicos")}
              data-aos="fade-up"
              data-aos-duration="1100"
            >
              <div className="bn">
                <img src={contabilidade} alt="" />
                <h1>Contabilidade</h1>
                <p>
                  Nosso departamento contábil está pronto a atender as
                  necessidades legais da sua empresa
                </p>
              </div>
            </button>
            <button
              onClick={() => history.push("/servicos")}
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="bn">
                <img src={empresa} alt="" />
                <h1>Abertura De Empresa</h1>
                <p>
                  Abra sua empresa com a estrutura societária mais adequada,
                  sempre com foco na economia tributária.
                </p>
              </div>
            </button>
            <button
              onClick={() => history.push("/servicos")}
              data-aos="fade-up"
              data-aos-duration="1300"
            >
              <div className="bn">
                <img src={administrative} alt="" />
                <h1>Fiscal</h1>
                <p>
                  Sua empresa com uma assessoria completa para atender a todas
                  as obrigações fiscais.
                </p>
              </div>
            </button>
            <button
              onClick={() => history.push("/servicos")}
              data-aos="fade-up"
              data-aos-duration="1400"
            >
              <div className="bn">
                <img src={financeiro} alt="" />
                <h1>Planejamento Tributário</h1>
                <p>
                  Avaliação de negócios e análise para mudanças de regime
                  tributário
                </p>
              </div>
            </button>
            <button
              onClick={() => history.push("/servicos")}
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <div className="bn">
                <img src={pessoal} alt="" />
                <h1>Departamento Pessoal</h1>
                <p>
                  Imprescindível para qualquer empresa, sendo responsável pela
                  execução de toda a rotina de departamento de pessoal.
                </p>
              </div>
            </button>
            <button
              onClick={() => history.push("/servicos")}
              data-aos="fade-up"
              data-aos-duration="1600"
            >
              <div className="bn">
                <img src={societario} alt="" />
                <h1>Societário</h1>
                <p>
                  Serviços de análise e assessoria da estrutura societária da
                  empresa para readequação e/ou dissolução.
                </p>
              </div>
            </button>
          </div>
        </div>
      </section>
      <section className="direto">
        <div className="direto-text flex_center_column">
          <h1>Gostou?! Converse agora mesmo conosco!</h1>
          <a href="https://api.whatsapp.com/send?l=pt_BR&phone=5504430261047">
            Enviar mensagem
          </a>
        </div>
      </section>
      <section className="about">
        <div className="container flex_center_row">
          <div className="about-img"></div>
          <div
            className="about-text"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <h1>Sobre nós</h1>
            <p>
              Como uma empresa contábil atualizada, nosso objetivo é inovar o
              seu processo de contabilidade, adaptando-o à nova realidade da era
              digital! Nosso objetivo na digitalização da contabilidade é fazer
              com que você receba de forma mais ágil os elementos de análise
              necessários para uma gestão mais eficiente do seu negócio.
            </p>
            <button
              onClick={() => history.push("/about")}
              className="btn"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              Saiba Mais
            </button>
          </div>
        </div>
      </section>

      {/* 
      <section className="HomeBlog">
        <div className="container flex_center_column">
          <div className="HomeBlog-title">
            <h1>Últimas Notícias</h1>
          </div>
          <div
            className="HomeBlog-banners flex_center_row"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {tableData}
          </div>
          <button onClick={() => history.push(`/blog`)} className="btn-mr">
            Veja Mais
          </button>
        </div>
      </section> */}
      {/* <div className="our-cli container flex_center_column">
        <div className="cli-title">
          <h1>Nossos Clientes</h1>
          <p>conheça os clientes e parceiros que confiam no nosso trabalho!</p>
        </div>
        <div className="cli-grid">
          <div className="cli">
            <img src={cli1} alt="" />
          </div>
          <div className="cli">
            <img src={cli2} alt="" />
          </div>
          <div className="cli">
            <img src={cli3} alt="" />
          </div>
          <div className="cli">
            <img src={cli4} alt="" />
          </div>
          <div className="cli">
            <img src={cli5} alt="" />
          </div>
          <div className="cli">
            <img src={cli6} alt="" />
          </div>
          <div className="cli">
            <img src={cli7} alt="" />
          </div>
          <div className="cli">
            <img src={cli8} alt="" />
          </div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
};
