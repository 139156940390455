import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import background_1 from "../../img/background/background.jpg";
import background_2 from "../../img/background/background2.jpg";
import background_3 from "../../img/background/background3.jpg";
import "./HomeSlider.css";

export const HomeSlider = () => {
  const history = useHistory();
  useEffect(() => {
    var slides = document.querySelectorAll(".baner");
    var imagens = document.querySelectorAll(".bc_img img");
    var btns = document.querySelectorAll(".dots");

    function manualNav(manual) {
      slides.forEach((slide) => {
        slide.classList.remove("active");

        btns.forEach((btn) => {
          btn.classList.remove("active");
        });
        imagens.forEach((img) => {
          img.classList.remove("active");
        });
      });
      slides[manual].classList.add("active");
      btns[manual].classList.add("active");
      imagens[manual].classList.add("active");
    }

    btns.forEach((btn, i) => {
      btn.addEventListener("click", () => {
        manualNav(i);
      });
    });

    function repeat() {
      let active = document.getElementsByClassName("active");
      let i = 1;
      var repeater = () => {
        setTimeout(function () {
          [...active].forEach((activeSlide) => {
            activeSlide.classList.remove("active");
          });

          slides[i].classList.add("active");
          btns[i].classList.add("active");
          imagens[i].classList.add("active");
          i++;

          if (slides.length === i) {
            i = 0;
          }
          if (i >= slides.length) {
            return;
          }
          repeater();
        }, 5000);
      };
      repeater();
    }
    repeat();
  }, []);
  return (
    <>
      <section className="home" id="home">
        <div className="bc_img">
          <img src={background_1} className="active" alt="" />
        </div>
        <div className="bc_img">
          <img src={background_2} alt="" />
        </div>
        <div className="bc_img">
          <img src={background_3} alt="" />
        </div>
        <div className="overlay"></div>
        <div className="filler"></div>
        <div className="slide">
          <div className="container">
            <div className="baner active">
              <h1>Abra já a sua</h1>
              <h2>Empresa!</h2>
              <p>
                Precisa de facilidade e praticidade para abrir a sua Empresa?
                <br />é Iguaçu Contábil!
              </p>

              <button onClick={() => history.push("/contato")} className="btn">
                Entrar em contato
              </button>
            </div>
            <div className="baner">
              <h1>Organização que</h1>
              <h2>sua empresa precisa</h2>
              <p>
                Cuidamos de todo o seu Departamento Pessoal <br />
                garantindo mais organização e total controle das rotinas.
              </p>
              <button onClick={() => history.push("/servicos")} className="btn">
                Saiba mais
              </button>
            </div>
            <div className="baner">
              <h1>Contabilidade</h1>
              <h1>Profissional</h1>
              <h2>e Serviços Fiscais</h2>
              <p>
                Alta performance para o seu negócio e segurança para as suas
                finanças.
              </p>

              <button onClick={() => history.push("/servicos")} className="btn">
                Nossos Serviços
              </button>
            </div>
            <div className="navigation">
              <div className="dots active"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
