import React, { useEffect } from "react";
import { Topper } from "../../components/topper/Topper";
import { MenuList } from "../../components/menu/MenuList";
import { Hero } from "../../components/hero/Hero";
import { Footer } from "../../components/footer/Footer";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "./ServicosPage.css";
import { Helmet } from "react-helmet";
import question from "../../img/question.png";
import arrow from "../../img/arrow_faq.png";
import work from "../../img/work.png";
import fracao from "../../img/fracao.png";
import grafico from "../../img/grafico.png";
import grafico2 from "../../img/grafico2.png";
import $ from "jquery";
import "../../App.css";
import AOS from "aos";
import "aos/dist/aos.css";

export const ServicosPage = () => {
  useEffect(() => {
    $("body").toggleClass("open");
    AOS.init({
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <>
      <Helmet>
        <title>Iguaçu Contábil | Serviços</title>
      </Helmet>
      <Topper></Topper>
      <MenuList logo={true}></MenuList>
      <Hero page="Serviços"></Hero>
      <section className="services-container">
        <div className="container">
          <div className="call-container">
            <div
              className="cs-text"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="cs-title">
                <h1 className="normal-title">
                  Como funciona a Iguaçu Contábil?
                </h1>
              </div>
              <div className="cs-sub-title">
                <p>
                  Somos um escritório de contabilidade, realizamos serviços para
                  pequenas, médias e grandes empresas em Maringá e região.
                  Prestamos serviços de contabilidade, regularização de empresas
                  incluindo abertura e baixa de CNPJ em Maringá e região
                </p>
              </div>
              <button className="cs-button">Solicitar um orçamento</button>
            </div>

            <img
              src={question}
              alt=""
              data-aos="zoom-in"
              data-aos-duration="1000"
              className="question-img"
            />
          </div>

          <div className="wk-pro">
            <div className="wk-title">
              <h1>Como funciona a rotina da contabilidade?</h1>
            </div>
            <div className="wk-cont">
              <div className="wk-img">
                <img src={work} alt="" />
              </div>
              <div className="wk-text">
                <div data-aos="zoom-in" data-aos-duration="500">
                  <h1>NOTAS FISCAIS</h1>
                  <p>
                    Não se preocupe, nosso sistema importa todas as suas Notas
                    Fiscais automaticamente e disponibiliza os impostos na
                    plataforma.
                  </p>
                </div>
                <div data-aos="zoom-in" data-aos-duration="1000">
                  <h1>FOLHA DE PAGAMENTO E PRÓ-LABORE</h1>
                  <p>
                    Tem funcionários ou sócios? Na plataforma, você nos informa
                    sobre as alterações e nós disponibilizamos todo o cálculo da
                    sua folha de pagamento.
                  </p>
                </div>
                <div data-aos="zoom-in" data-aos-duration="1500">
                  <h1>MOVIMENTAÇÃO BANCÁRIA PJ</h1>
                  <p>
                    Você nos envia a movimentação da empresa (despesas, extratos
                    bancários…), nós fazemos toda a sua contabilidade em dia e
                    disponibilizamos na plataforma.
                  </p>
                </div>
                <div data-aos="zoom-in" data-aos-duration="2000">
                  <h1>IMPOSTOS DISPONÍVEIS NA PLATAFORMA</h1>
                  <p>
                    Pronto, fazemos sua contabilidade e disponibilizamos seus
                    impostos para pagamento.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className="faq flex_center_column"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="title">
              <h1>Dúvidas frequentes</h1>
            </div>
            <Accordion allowZeroExpanded className="acord-container">
              <AccordionItem className="acord-box">
                <AccordionItemHeading>
                  <AccordionItemButton className="acord-title">
                    <h1>Quanto em média custa um contador?</h1>
                    <img src={arrow} alt="" />
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="acord-text">
                  Os preços dos serviços de contabilidade podem variar muito, os
                  aspectos mais comuns que podem interferir no valor do serviço
                  a ser cobrado, estão relacionados ao volume de dados que será
                  tratado na contabilidade, os principais aspectos são os
                  seguintes: Forma de tributação da empresa, se ela está
                  enquadrada no regime especial do Simples Nacional, ou é
                  tributada no Lucro Presumido, Lucro Real, são fatores que
                  interferem na precificação do serviço. Outro fator que compõe
                  a planilha de precificação é o ramo de atividade que a empresa
                  opera, alguns seguimentos têm sua operação mais complexa como
                  por exemplo: Indústria. E por fim e não mais importante, o
                  número de colaboradores que a empresa possui, tal informação é
                  fundamental na precificação tendo em vista que de modo geral
                  uma empresa com mais colaboradores terá uma rotatividade maior
                  no seu quadro, demandando mais trabalho por parte da
                  contabilidade. Solicite um orçamento personalizado.
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem className="acord-box">
                <AccordionItemHeading>
                  <AccordionItemButton className="acord-title">
                    <h1>Como faço para saber o custo de um colaborador?</h1>
                    <img src={arrow} alt="" />
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="acord-text">
                  De forma bem simples, é possível calcular o valor aproximado
                  do custo mensal de um colaborador realizando a seguinte conta:
                  <img src={fracao} alt="fracao" id="fracao" />
                  <br />
                  A fração acima representa o custo do colaborador a cada mês
                  trabalhado para fins de férias e 13º Salário. Uma vez
                  calculada a fração mensal (1/12 avos), esta será somada ao
                  salário e todas as demais verbas para computar o custo do
                  funcionário já acrescido do 13º salário. A fração das férias
                  será acrescida de mais 1/3 da fração para se obter o valor do
                  abono de férias mensal. <br />
                  <br />* Horas Extras <br /> ** Descanso Semanal Remunerado{" "}
                  <br />
                  *** Adicionais (anuênio – quinquênio – adicionais de
                  assiduidade, Insalubridade, Periculosidade, etc) <br />
                  <br /> Obs: Alguns gestores entendem que a fração deva ser
                  computada uma única vez em razão do colaborador não trabalhar
                  01 mês ao ano porque estará em gozo de férias. Neste caso
                  considere a divisão por 11 e não doze, para se obter o valor
                  da fração. Ao final, somados todos os valores, faremos a
                  seguinte equação: somamos todos os valores e acrescentamos 8%
                  que corresponde ao FGTS que incide sobre todas as verbas
                  calculadas, neste ponto temos o valor final mensal do
                  colaborador. Abaixo segue um exemplo.
                  <br />
                  <img src={grafico} alt="grafico" id="grafico" />
                  <br />
                  Os valores acima são exemplificativos, e foram calculados com
                  base em uma jornada de 220 horas por mês, para se realizar um
                  cálculo mais exato para atender a necessidades
                  individualizadas, pode-se usar a metodologia do apontamento de
                  trabalho onde um consultor ou gestor poderá designar alguém
                  para realizar a cronometragem do trabalho desenvolvido
                  diariamente pelo colaborador. Quando o empregador PJ não
                  estiver enquadrado no simples nacional, haverá incidência da
                  contribuição patronal ao INSS na alíquota de 20% sobre o total
                  das remunerações.
                  <br />
                  <br /> Além dessa contribuição há também a GILRAT
                  (Contribuição do Grau de Incidência de Incapacidade Laborativa
                  decorrente dos Riscos Ambientais do Trabalho) na alíquota de
                  1%, 2% e 3%, corrigidas pelo Fator Acidentário de Prevenção –
                  FAP, ou seja, multiplicado por 0,5, 1,0 ou 2,0, e a
                  contribuição para Terceiros, sistema S (SESI, SENAI, SENAC,
                  SESC, etc.), na alíquota de 5,8% para empresas em geral.
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem className="acord-box">
                <AccordionItemHeading>
                  <AccordionItemButton className="acord-title">
                    <h1>Preciso de uma conta PJ para receber pagamentos?</h1>
                    <img src={arrow} alt="" />
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="acord-text">
                  A abertura de conta digital ou convencional de uma de PJ
                  (Pessoa Jurídica) NÃO É OBRIGATÓRIA, contudo, se torna
                  fundamental se você pretende iniciar seu negócio de forma
                  organizada. Mesmo não sendo obrigatória, por serem pessoas
                  distintas, a PF (Pessoa Física) do sócio ou titular da empresa
                  e a Pessoa Jurídica propriamente dita, não se pode receber ou
                  pagar uma conta da PJ na PF, portanto, é importante observar
                  que se os recebimentos forem realizados através de meios de
                  pagamento bancários convencionais e/ou digitais, você
                  fatalmente precisará ter uma conta bancária na sua PJ, a menos
                  que seus recebimentos sejam todos em moeda corrente
                  (dinheiro), neste caso não há necessidade de tal conta.
                  Exceção exclusivamente para o MEI, cujo controle é totalmente
                  informal.
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem className="acord-box">
                <AccordionItemHeading>
                  <AccordionItemButton className="acord-title">
                    <h1>
                      Como encontrar o ponto de equilíbrio da minha empresa?
                    </h1>
                    <img src={arrow} alt="" />
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="acord-text">
                  Ponto de equilíbrio é o faturamento mínimo que a empresa
                  precisa realizar mensalmente para cobrir todos os custos e
                  despesas sem sobrar lucro, ou seja, é quando a empresa chega
                  no empate entre faturamento x custos e despesas. Toda empresa
                  seja de qual segmento for, possui custos e despesas fixas e
                  variáveis. De forma simplista, podemos dizer que os custos e
                  despesas fixos são aqueles que não variam conforme a empresa
                  venda mais ou menos, ex: aluguel, energia elétrica, etc.,
                  enquanto que as variáveis são aqueles que variam a cada venda
                  realizada, ex: comissão vendas, custo de mercadoria, impostos,
                  etc. <br />
                  <br /> Primeiramente você precisa identificar quais são estes
                  custos e despesas. A contabilidade entende como variável
                  aquele custo ou despesa que está intimamente ligado com a
                  produção, ex: matéria prima, mão de obra da equipe de
                  produção, comissão de vendedores, entre outros. Os custos e
                  despesas que não estão diretamente ligados à produção e ou
                  comercialização são considerados fixos, apesar de muitos deles
                  serem diferentes a cada mês como é o exemplo da energia
                  elétrica. Esses custos e despesas serão classificados
                  diferentemente conforme a sua atividade. Se for indústria,
                  pode ser custo/despesa variável, se for comércio pode ser
                  fixo, assim por diante. Mas, de forma didática para entender o
                  mecanismo de cálculo do ponto de equilíbrio, vamos
                  exemplificar com uma empresa da atividade comercial. <br />
                  <br /> Uma empresa do ramo de comércio, ou seja, que compra e
                  revende mercadorias sem nenhum processo de industrialização da
                  mesma, normalmente tem como custo/despesa variável e fixa as
                  que listamos abaixo: Custo de compra (Variável); Comissão de
                  vendas (Variável); Impostos (Variável); Frete (Variável);
                  Embalagem (Variável); Gastos com Call center para venda
                  (Variável); Marketing (Variável); Aluguel (Fixo); Folha
                  Pagamento pessoal administrativo (Fixo); Água, Energia e
                  Telefone (Fixo), pro-labore (remuneração do sócio) (fixo).
                  <br />
                  <br />
                  Conforme tabela abaixo precisamos realizar alguns cálculos
                  para chegarmos a margem de contribuição, que é o percentual
                  médio que a empresa tem excluindo do valor da venda os custos
                  variáveis. Esse percentual nós vamos utilizar para encontrar
                  nosso ponto de equilíbrio do negócio.
                  <br />
                  <br />
                  <img src={grafico2} alt="grafico2" id="grafico2" />
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
};
