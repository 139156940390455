import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Topper } from "../../components/topper/Topper";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { MenuList } from "../../components/menu/MenuList";
import { Hero } from "../../components/hero/Hero";
import { Footer } from "../../components/footer/Footer";
import $ from "jquery";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import "./BlogPage.css";

export const BlogPage = () => {
  const conexao = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
  });
  const history = useHistory();
  const [blog, setBlog] = useState([
    {
      titulo: "",
      descricao: "",
      conteudo: "",
    },
  ]);
  const doGetBlog = async () => {
    const response = await conexao.get(`/blog`);
    setBlog(response.data);
  };

  useEffect(() => {
    $("body").toggleClass("open");
    AOS.init({
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);

  useEffect(() => {
    doGetBlog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableData =
    blog.length === 0 ? (
      <p>Sem Blog Adicionado!</p>
    ) : (
      blog.map((row, index) => {
        return (
          <button
            onClick={() => history.push(`/blog/noticia/${row._id}`)}
            className="news-box blog-box"
            data-aos="fade-up"
            data-aos-duration="1000"
            key={index}
          >
            <h2>{row.descricao}</h2>
            <h1>{row.titulo}</h1>
            <div className="bp-conteudo">
              <p>{row.conteudo}</p>
            </div>
            <div className="news-arrow">Saiba Mais</div>
          </button>
        );
      })
    );

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Iguaçu Contábil | Notícias</title>
        </Helmet>
      </HelmetProvider>
      <Topper></Topper>
      <MenuList></MenuList>
      <Hero page="Notícias"></Hero>
      <section className="news blog">
        <div className="container flex_center_row">
          <div className="nw-bn-container">
            <div className="news-banners blog-banners flex_center_row">
              {tableData}
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
};
