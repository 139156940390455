/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import tempAlert from "../../components/alert/alert";
import logoWhite from "../../img/logos/white.png";
import logoNormal from "../../img/logos/logo.png";

export const BlogEdit = () => {
  const conexao = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
  });
  const history = useHistory();
  const { idParaEditar } = useParams();
  const [blog, setBlog] = useState({
    titulo: "",
    descricao: "",
    conteudo: "",
    photo: "",
  });

  const doGetById = async () => {
    const response = await conexao.get(`/blog/${idParaEditar}`, blog);
    setBlog(response.data);
  };

  useEffect(() => {
    doGetById();
  }, []);

  const doPut = async () => {
    await conexao.put(`/blog/${idParaEditar}`, blog);
    tempAlert(`Notícia alterada com sucesso!`, 5000);
    history.push("/manager");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    doPut();
  };

  const handleChange = (event) => {
    const novopedido = { ...blog, [event.target.name]: event.target.value };
    setBlog(novopedido);
  };

  return (
    <>
      <div className="floating_nav_bar">
        <nav className="header container flex_center_row">
          <a href="/" className="logo flex_center_row">
            <img src={logoWhite} className="img1" alt="" />
            <img src={logoNormal} className="img2 ativo" alt="" />
            <div className="logo-text flex_center_column ">
              <h1 className="ativo">IGUAÇU</h1>
              <p className="ativo">CONTÁBIL</p>
            </div>
          </a>
          <div className="menu_list">
            <ul className="flex_center_row">
              <button
                onClick={() => history.push("/manager")}
                className="btn-click bl-exit"
              >
                Voltar
              </button>
            </ul>
          </div>
        </nav>
      </div>
      <div className="bn-container">
        <form onSubmit={handleSubmit} className="bn-form">
          <div className="bn-input">
            Título
            <input
              type="text"
              required
              name="titulo"
              onChange={handleChange}
              value={blog.titulo}
            ></input>
          </div>
          <div className="bn-input">
            Descrição
            <input
              type="text"
              required
              name="descricao"
              onChange={handleChange}
              value={blog.descricao}
            ></input>
          </div>
          <div className="bn-input">
            Conteúdo
            <textarea
              type="text"
              rows="15"
              required
              name="conteudo"
              onChange={handleChange}
              value={blog.conteudo}
            ></textarea>
          </div>
          <button className="bn-btn">Enviar</button>
          <button className="bn-btn" onClick={() => history.push("/manager")}>
            Cancelar
          </button>
        </form>
      </div>
    </>
  );
};
