import React, { useState } from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faCheck,
  faLongArrowAltRight,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { tempAlert } from "../../libraries/tempAlert";

export const Footer = () => {
  const [newsLetterEmail, setNewsLetterEmail] = useState("");
  const conexao = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
  });

  const handleNewsletter = async (event) => {
    event.preventDefault();
    const newEmailContent = {
      to: "atendimento@iguacucontabil.com.br",
      subject: "Newsletter - Iguaçu Site",
      text: `Olá, gostaria de receber informativos da empresa no meu email: ${newsLetterEmail}`,
    };
    await conexao
      .post(`/email`, newEmailContent)
      .then(() => {
        setNewsLetterEmail("");
        tempAlert("Email cadastrado com sucesso!");
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <footer>
        <div className="container">
          <div className="bib-vers flex_center_column">
            <span className="leftq">&ldquo;</span>
            <h2>
              Entrega o teu caminho ao Senhor; confia nele, e ele tudo fará.
            </h2>
            <small>Salmo 37:5</small>
            <span className="rightq">&bdquo; </span>
          </div>
          <div className="contatos">
            <div className="contatos-box telefone">
              <h1>Ligue-nos agora</h1>
              <p>Telefone : (44) 3026-1047</p>
              <p>Celular : (44) 9.8406-8195</p>
            </div>
            <div className="contatos-box email">
              <h1>Email</h1>
              <p>atendimento@iguacucontabil.com.br</p>
            </div>
            <div className="contatos-box endereco">
              <h1>Faça-nos uma visita</h1>
              <p>Endereço : Av. Morangueira, 426</p>
              <p>Cep: 87.030-253 - Maringá - PR</p>
            </div>
          </div>
          <div className="top-footer">
            <div className="about">
              <h1>Sobre Nós</h1>
              <p>
                Iguaçu Contábil é uma empresa que presta serviços para micro,
                pequenas e médias empresas nas áreas de Contabilidade...
                <a href="./pages/about.html">saiba mais</a>
              </p>
              <div className="funcionamento flex_center_row">
                <div className="dias">
                  <p>SEGUNDA A SEXTA</p>
                  <p>SÁBADO E DOMINGO</p>
                  <p>FERIADOS</p>
                </div>
                <div className="horarios">
                  <p>7:45 ÀS 12:00 e 13:00 ÀS 17:30</p>
                  <p>FECHADO</p>
                  <p>FECHADO</p>
                </div>
              </div>
            </div>
            <div className="services">
              <h1>Links úteis</h1>
              <div className="serv">
                <ul>
                  <li>
                    <a href="https://www.gov.br/receitafederal/pt-br/assuntos/agenda-tributaria">
                      Agenda Tributária
                    </a>
                  </li>
                  <li>
                    <a href="https://www3.bcb.gov.br/CALCIDADAO/publico/exibirFormCorrecaoValores.do?method=exibirFormCorrecaoValores">
                      Calculadora do cidadão
                    </a>
                  </li>
                  <li>
                    <a href="https://www.receita.fazenda.gov.br/Aplicacoes/SSL/ATCTA/CPF/ConsultaSituacao/ConsultaPublica.asp">
                      Consulta CPF
                    </a>
                  </li>
                  <li>
                    <a href="http://www.receita.fazenda.gov.br/pessoajuridica/cnpj/cnpjreva/cnpjreva_solicitacao.asp">
                      Consulta CNPJ
                    </a>
                  </li>
                  <li>
                    <a href="http://www.cdw.fazenda.pr.gov.br/cdw/emissao/certidaoAutomatica">
                      Certidão Automatica - Paraná
                    </a>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a href="http://www.cnae.ibge.gov.br/"> Consulta CNAE</a>
                  </li>
                  <li>
                    <a href="http://trabalho.gov.br/portal-mte/">
                      Ministério do Trabalho
                    </a>
                  </li>
                  <li>
                    <a href="http://www.portaldoempreendedor.gov.br/">
                      Portal do MEI
                    </a>
                  </li>
                  <li>
                    <a href="http://www8.receita.fazenda.gov.br/SimplesNacional/">
                      Simples Nacional
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="newsletter">
              <h1>NEWSLETTER</h1>
              <p>Receba nossos informativos.</p>
              <form className="input-group" onSubmit={handleNewsletter}>
                <input
                  type="email"
                  id="newsletter"
                  autoComplete="email"
                  placeholder="Email"
                  className="sbt-input"
                  value={newsLetterEmail}
                  onChange={(input) => setNewsLetterEmail(input.target.value)}
                />
                <button type="submit" id="sbt-news" className="sbt-news">
                  <FontAwesomeIcon
                    className="fa-arrow"
                    icon={faLongArrowAltRight}
                  />
                  <FontAwesomeIcon className="fa-check" icon={faCheck} />
                </button>
              </form>
            </div>
          </div>
          <div className="bottom-footer flex_center_row">
            <p>
              © 2020 Desenvolvido por
              <a
                href="https://jordaoqualho.vercel.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Jordão Qualho
              </a>
            </p>
            <nav className="social-media flex_center_row">
              <a
                href="https://www.instagram.com/iguacucontabil/?hl=pt-br"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon className="social-i" icon={faInstagram} />
              </a>
              <a
                href="https://api.whatsapp.com/send?l=pt_BR&phone=5504430261047"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon className="social-i" icon={faWhatsapp} />
              </a>
              <a
                href="https://www.google.com/search?bih=937&biw=1920&rlz=1C1GCEA_enBR915BR915&hl=pt-BR&tbm=lcl&sxsrf=ALeKk03Wp-sexg3Z_Y5K-bBTpefzEIqc8Q%3A1613445325192&ei=zTgrYNGwC4265OUP9uGVoAs&q=igua%C3%A7u+contabil+maringa&oq=igua%C3%A7u+contabil+maringa&gs_l=psy-ab.3...3992.5501.0.5656.8.6.0.0.0.0.272.272.2-1.1.0....0...1c.1.64.psy-ab..7.1.272...0i22i30k1.0.8kcyJJuSrRo#rlfi=hd:;si:2211826999536500671;mv:[[-23.408748722680972,-51.93057588063004],[-23.409108677319026,-51.930968119369965]]"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon className="social-i" icon={faMapMarkerAlt} />
              </a>
            </nav>
          </div>
        </div>
      </footer>
    </>
  );
};
