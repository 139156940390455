import React from "react";
import BackToTop from "../../img/icones/toTop.png";
import "./Topper.css";

export const Topper = () => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div>
      <button className="btn_top" onClick={() => scrollTop()}>
        <img src={BackToTop} alt="" />
      </button>
    </div>
  );
};
