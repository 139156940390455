import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Loader from "react-loader-spinner";
import tempAlert from "../../components/alert/alert";
import axios from "axios";
import { useHistory } from "react-router-dom";
import login from "../../img/login2x.png";
import AOS from "aos";
import "aos/dist/aos.css";
import "./login.css";
import $ from "jquery";

export const LoginPage = () => {
  const conexao = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
  });
  const history = useHistory();
  const [inputState, setInputState] = useState({
    lgUser: "",
    lgPassword: "",
    lgState: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    doPost();
  };

  const doPost = async () => {
    setIsLoading(true);

    try {
      const res = await conexao
        .post("/user/login", {
          usuario: inputState.lgUser,
          senha: inputState.lgPassword,
        })
        .then(setIsLoading(false));
      tempAlert(res.data, 3000);
      if (!(res.data === "Senha ou usuário inválido!")) {
        setInputState({ ...inputState, lgState: true });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    const mudandoInput = {
      ...inputState,
      [event.target.name]: event.target.value,
    };
    setInputState(mudandoInput);
  };
  useEffect(() => {
    if (inputState.lgState) {
      history.push("/manager");
    }
  }, [inputState.lgState, history]);

  useEffect(() => {
    $("body").toggleClass("open");
    AOS.init({
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Iguaçu Contábil | Login</title>
        </Helmet>
      </HelmetProvider>
      <div className="login-container">
        <div className="lg-box" data-aos="zoom-out-up" data-aos-duration="1000">
          <h1 className="lg-title">Login</h1>
          <img src={login} alt="" className="lg-img" />
          <form className="lg-forms" onSubmit={handleSubmit}>
            <input
              type="text"
              autoFocus
              required
              autoComplete=""
              name="lgUser"
              onChange={handleChange}
              className="lg-inpt"
              placeholder="Digite seu email ou usuário"
            />
            <input
              type="password"
              autoComplete="current-password"
              required
              name="lgPassword"
              onChange={handleChange}
              className="lg-inpt"
              placeholder="Digite sua senha"
            />
            <button className="lg-button">
              {!isLoading ? (
                <> Entrar </>
              ) : (
                <Loader type="TailSpin" color="#fff" height={25} width={25} />
              )}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
