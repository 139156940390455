import React, { useEffect } from "react";
import full_logo from "../../img/logo/full.png";
import $ from "jquery";

export const LoadingScreen = () => {
  useEffect(() => {
    $(window).on("load", function () {
      $("#loading-screen .loading-bar").delay(500).fadeOut("slow");
      $("#loading-screen").delay(500).fadeOut("slow");
    });
  }, []);
  return (
    <>
      <div id="loading-screen">
        <div className="loading-animation">
          <img src={full_logo} className="loading-logo" alt="" />
          <div className="loading-bar" />
        </div>
      </div>
    </>
  );
};
