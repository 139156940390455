import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import tempAlert from "../../components/alert/alert";
import logoWhite from "../../img/logos/white.png";
import logoNormal from "../../img/logos/logo.png";

export const BlogNew = () => {
  const conexao = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
  });
  const history = useHistory();
  const [pedido, setBlog] = useState({
    titulo: "",
    descricao: "",
    conteudo: "",
    photo: "",
  });

  // nfn - comando para criar função anonima
  const doPost = async () => {
    await conexao.post("/blog", pedido);
    tempAlert(`Blog adicionado com sucesso!`, 5000);
    history.push("/manager");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    doPost();
  };

  const handleChange = (event) => {
    const novoBlog = { ...pedido, [event.target.name]: event.target.value };
    setBlog(novoBlog);
  };

  return (
    <>
      <div className="floating_nav_bar m_bar">
        <nav className="header container flex_center_row">
          <a href="/" className="logo flex_center_row">
            <img src={logoWhite} className="img1" alt="" />
            <img src={logoNormal} className="img2 ativo" alt="" />
            <div className="logo-text flex_center_column ">
              <h1 className="ativo">IGUAÇU</h1>
              <p className="ativo">CONTÁBIL</p>
            </div>
          </a>
          <div className="menu_list">
            <ul className="flex_center_row">
              <button
                onClick={() => history.push("/manager")}
                className="btn-click bl-exit"
              >
                Voltar
              </button>
            </ul>
          </div>
        </nav>
      </div>
      <div className="bn-container">
        <form onSubmit={handleSubmit} className="bn-form">
          <div className="bn-input">
            Título
            <input
              type="text"
              required
              name="titulo"
              onChange={handleChange}
            ></input>
          </div>
          <div className="bn-input">
            Assunto
            <input
              type="text"
              required
              name="descricao"
              onChange={handleChange}
            ></input>
          </div>
          <div className="bn-input">
            Conteúdo
            <textarea
              type="text"
              rows="15"
              required
              name="conteudo"
              onChange={handleChange}
            ></textarea>
          </div>
          <button className="bn-btn">Enviar</button>
          <button className="bn-btn" onClick={() => history.push("/manager")}>
            Cancelar
          </button>
        </form>
      </div>
    </>
  );
};
