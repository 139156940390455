import React, { useState } from "react";
import { Route, Switch } from "react-router";
import { BlogEdit } from "./BlogEdit";
import { BlogList } from "./BlogList";
import { BlogNew } from "./BlogNew";

export const BlogManter = () => {
  const [statusPesquisa, setStatusPesquisa] = useState({
    páginaAtual: 0,
    termoDePesquisa: "",
  });

  return (
    <>
      <Switch>
        <Route exact path="/manager">
          <BlogList
            statusPesquisa={statusPesquisa}
            setStatusPesquisa={setStatusPesquisa}
          ></BlogList>
        </Route>
        <Route path="/manager/novo">
          <BlogNew></BlogNew>
        </Route>
        <Route path="/manager/editar/:idParaEditar">
          <BlogEdit></BlogEdit>
        </Route>
      </Switch>
    </>
  );
};
