import React, { useEffect } from "react";
import { Footer } from "../../components/footer/Footer";
import { Topper } from "../../components/topper/Topper";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { HistoriaSlider } from "../../components/historia/HistoriaSlider";
import { MenuList } from "./../../components/menu/MenuList";
import { Hero } from "../../components/hero/Hero";
import "./about.css";
import AOS from "aos";
import $ from "jquery";
import "aos/dist/aos.css";

export const AboutPage = () => {
  useEffect(() => {
    $("body").toggleClass("open");
    AOS.init({
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Iguaçu Contábil | Sobre Nós</title>
        </Helmet>
      </HelmetProvider>
      <Topper></Topper>
      <MenuList></MenuList>
      <Hero page="Sobre nós"></Hero>
      <section className="historia">
        <div className="container">
          <div className="what-do flex_center_row">
            <div className="text" data-aos="fade-up" data-aos-duration="1000">
              <h1>O que fazemos?</h1>
              <h3>
                Ajudamos empresários, gestores financeiros, gestores de RH,
                entre outros, a resolverem seus problemas contábeis, fiscais e
                trabalhistas através de uma consultoria customizada para o seu
                negócio.
              </h3>
              <p>
                Nosso escritório presta serviços para micro, pequenas e médias
                empresas nas áreas de Contabilidade, Dpto Fiscal/Tributário e
                Dpto Pessoal/Trabalhista. Nossa equipe formada por profissionais
                capacitados para atender nos seguimentos da indústria, comércio
                e de prestação de serviços em geral.
              </p>
            </div>
            <div
              className="about-img"
              data-aos="fade-left"
              data-aos-duration="1000"
            ></div>
          </div>
          <HistoriaSlider></HistoriaSlider>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
};
