// Alerta Personalizado
export function tempAlert(msg, duration = 2500) {
  var el = document.createElement("div");
  el.setAttribute(
    "style",
    "position:fixed;top:0%;left:50%;padding: 10px 20px;border-radius: 5px;margin-top: 20px;transform: translate(-50%); background-color: var(--dourado);font-size: 20px;color:white;z-index:1000;"
  );
  el.innerHTML = msg;
  setTimeout(function () {
    el.parentNode.removeChild(el);
  }, duration);
  document.body.appendChild(el);
}
