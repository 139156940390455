import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { Footer } from "../../components/footer/Footer";
import { Hero } from "../../components/hero/Hero";
import { MenuList } from "../../components/menu/MenuList";
import { Topper } from "../../components/topper/Topper";
import "./NoticiaPage.css";

export const NoticiaPage = () => {
  const conexao = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
  });
  const { idDaNoticia } = useParams();
  const [blog, setBlog] = useState({
    titulo: "",
    descricao: "",
    conteudo: "",
  });

  const doGetById = async () => {
    const response = await conexao.get(`/blog/${idDaNoticia}`);
    setBlog(response.data);
  };

  useEffect(() => {
    doGetById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Iguaçu Contábil | Notícias</title>
        </Helmet>
      </HelmetProvider>
      <Topper></Topper>
      <MenuList></MenuList>
      <Hero page={blog.titulo} noticia={true}></Hero>
      <section class="art">
        <div class="container flex_center_column" key={blog.id}>
          <p>{blog.conteudo}</p>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
};
